<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-wrap f-between p-5 m-title-modal">
          <p class="large--title m-0 mb-2">
            {{
              $t("message.edit_m", {
                m: $t("message.filial"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
                <el-col :span="24">
                  <el-form-item :label="$t('message.name')" prop="name" class="label_mini">
                    <el-input
                      :placeholder="$t('message.name')"
                      v-model="form.name"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item :label="$t('message.calculation_types')" class="label_mini" prop="calculation_type_id">
                    <select-calculation-types
                      :size="'medium'"
                      :placeholder="$t('message.calculation_types')"
                      :id="form.calculation_type_id"
                      v-model="form.calculation_type_id"
                    >
                    </select-calculation-types>
                  </el-form-item>
                </el-col>

                <el-col :span="12">
                  <el-form-item :label="$t('message.profit_in_percents')" prop="min_profit">
                    <crm-only-number-input
                      v-model="form.min_profit"
                      :inputValue="form.min_profit"
                      @c-change="checkProfit"
                    ></crm-only-number-input>
                  </el-form-item>
                </el-col>
                
                <el-col :span="12">
                  <el-form-item
                    :label="$t('message.categories')"
                    class="label_mini"
                    prop="category_ids"
                  >
                      <el-tree
                        ref="category"
                        :data="updatedCategories"
                        show-checkbox
                        node-key="id"
                        :default-checked-keys="form.category_ids"
                        :props="defaultProps">
                      </el-tree> 
                  </el-form-item>
                </el-col>
              
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import CrmOnlyNumberInput from '@/components/crm/crm-only-number-input';
import selectCalculationTypes from "@/components/inventory/select-calculation-types";

export default {
  mixins: [form, drawer, show],
  components: { CrmOnlyNumberInput, selectCalculationTypes },
  name: "filials",
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      updatedCategories: []
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "filials/rules",
      model: "filials/model",
      columns: "filials/columns",
      categories: "categories/treeInventory",
    }),
  },
  methods: {
    ...mapActions({
      update: "filials/update",
      show: "filials/show",
      updateInventory: "categories/treeInventory",
    }),
    afterOpen() {
      this.updateInventory().then(res => {
        this.updatedCategories = JSON.parse(JSON.stringify(this.categories));
      });
      this.fetchData();
    },
    submit(close = true) {
      if(this.$refs.category){
        this.form.category_ids = this.$refs.category.getCheckedKeys();
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.update(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 200) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
	checkProfit(profit){
		if (parseFloat(profit) > 0 && parseFloat(profit) <= 99){
			this.form.min_profit = profit;
		}
		else this.form.min_profit = null;
	}
  },
};
</script>
